// 网站配置
module.exports = {
    //静态资源地址
    STATIC_URL: 'https://apis.henbio.com/',  //'http://apistest.henbio.cn/',
    //服务端api请求地址,不含路径
    BASE_URL: 'https://apis.henbio.com/web/', //'http://apistest.henbio.cn/web/',
    // 网站项目名称
    APP_NAME: "翰佰尔生物客户服务系统",
    //网站描述
    APP_DESCRIPTION: '广西翰佰尔生物科技有限公司是一家专注于生物医学大数据分析、生物软件开发、生物医学分析工作站的开发、课题基础研究的公共数据挖掘、分子实验验证等新型生物科技公司。',
    //网站关键字
    APP_KEYWORDS: '翰佰尔生物、高通量测序、测序,美吉生物，转录组，宏基因组，简化基因组，外显子测序，测序，微生物测序',
    //验证用户名的正则表达
    USERNAME_REGULAR: /^[a-z][a-z0-9_-]{1,19}$/,// /^[a-zA-Z0-9_-]{2,20}$/,
    //验证手机的正则表达式
    MOBILE_REGULAR: /^1[3456789]\d{9}$/,
    //验证邮箱的正则表达式
    EMAIL_REGULAR: /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@([a-zA-Z0-9]+[-.])+([a-z]{2,5})$/, // /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
    //验证密码的正则表达式，正则验证密码包含大小写字母和数字组成，8到20位之间
    PASSWORD_REGULAR: /^(?![0-9]+$)(?![a-zA-Z]+$)[a-zA-Z0-9]{8,20}$/,
    // //验证密码的正则表达式，正则验证密码包含大小写字母，数字，特殊字符，6到32位之间
    // PASSWORD_REGULAR: /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*)(?=.*[`~!@#$%^&*()_\-+=<>.?:"{}].*).{6,32}$/,
    //验证文件夹名称
    DIRNAME_REGULAR: /^[A-Za-z0-9_-]+$/,
    //页面脚部信息
    FOOT_COMPANY: {
        NAME: '广西翰佰尔生物科技有限公司',
        ADDRESS: '广西南宁市高新区高科路8号，南宁创客城1号楼A座6层',//'广西南宁市高新区高科路8号，南宁创客城1号楼A座615',
        TELEPHONE: '0771-3939396',
        QQ: 3171300547,
        EMAIL: 'marketing@henbio.com',
        COPYRIGHT_COM: '© 2022-2023 HiOmics 版权所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn" target="_blank">桂ICP备2022006677号-2</a>&nbsp;&nbsp;广西翰佰尔生物科技有限公司',
        COPYRIGHT_CN: '© 2022-2023 HiOmics 版权所有&nbsp;&nbsp;<a href="https://beian.miit.gov.cn" target="_blank">桂ICP备2022006677号-1</a>&nbsp;&nbsp;广西翰佰尔生物科技有限公司'
    },    
    //最大上传文件大小，单位：字节（b），默认为：10G，转换单位：1024
    MAX_UPLOAD_SIZE: 10737418240,
    //最大上传文件大小错误提示
    MAX_UPLOAD_SIZE_ERROR_TIPS: '上传文件大小不能超过10GB!',
    //输入提示信息
    INPUT_PROMPT:{
        PASSWORD: '长度为8~20个字符， 必须包含字母和数字，如：32pAbdkp'
    },
    CURRENCY_NAME:'翰币',
    EN_CURRENCY_NAME:'Hanbi',
    //查询间隔时间
    INTERVAL_TIME_FOR_QUERY: 3000,
    //查询支付成功延时关闭支付窗口，支付回调需要一定时间执行业务逻辑，此处做延时处理
    PAY_SUCCESS_DELAY_CLOSE: 1500,
    //英文配置部分
    EN_MAX_UPLOAD_SIZE_ERROR_TIPS: 'The size of the uploaded file cannot exceed 10GB!',
    //页面脚部信息
    EN_FOOT_COMPANY: {
        NAME: 'Henbio Co., Ltd',
        ADDRESS: '10101 Southport Rd SW, Calgary, AB T2W 3N2',
        FACEBOOK: '',
        EMAIL: 'Calgaryoffice@henbio.com',
        COPYRIGHT_COM: '© 2022-2023 HiOmics. All rights reserved. Guangxi Henbio Technology Co., Ltd. &nbsp;<a href="https://beian.miit.gov.cn" target="_blank">ICP No. 2022006677-2.</a>',
        COPYRIGHT_CN: '© 2022-2023 HiOmics. All rights reserved. Guangxi Henbio Technology Co., Ltd. &nbsp;<a href="https://beian.miit.gov.cn" target="_blank">ICP No. 2022006677-1.</a>'
    },
    //输入提示信息
    EN_INPUT_PROMPT:{
        PASSWORD: 'It is 8-20 characters long and must contain letters and numbers, such as：32pAbdkp'
    },    
    PAGE_Refresh_TIME: 1000 * 60, //页面定时刷新时间为1分钟，1000为1秒
    //工具详情技术提示内容
    TOOL_DETAIL_TIPS:'<div style="color:#7D7D7D">用户运行有疑问可加入翰佰尔技术交流群咨询解决方法</div><div style="color:#F26434">技术交流QQ群号：541781673</div>',
    TOOL_DETAIL_TIPS_EN:"<div style='color:#7D7D7D'>If users have any questions about running the software, they can join the Hanbai'er Technical Exchange Group for assistance and solutions.</div><div style='color:#F26434;margin-top:10px'>Technical Exchange QQ Group Number: 541781673</div>"
};